@import './assets/css/reset.css';
@import './assets/css/plugins.css';
@import './assets/css/style.css';
@import './assets/css/color.css';

@import '~antd/dist/antd.css';

/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.custom-checkout .ant-space {
  width: 100%;
}

.styles-module_carousel-arrow__26sRw {
  z-index: 999;
  background: #000;
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 50%;
  content: "<"; 
  color: #fff;
  font-family: Font Awesome\ 5 Pro;
  font-weight: 200;
  font-size: 20px;
}
/* start loader */
#load_div {
    padding-top: 3px;
    color: #8B0000;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: baseline;
    padding-top:70px;
}
  
.lds-ellipsis { display: inline-block; position: relative; width: 80px; height: 80px; } 
.lds-ellipsis div {position: absolute;top: 33px;width: 13px;height: 13px;border-radius: 50%;background: #B0C4DE;animation-timing-function: cubic-bezier(0, 1, 1, 0);} 
.lds-ellipsis div:nth-child(1) { left: 8px; animation: lds-ellipsis1 0.6s infinite; } 
.lds-ellipsis div:nth-child(2) { left: 8px;  animation: lds-ellipsis2 0.6s infinite; } 
.lds-ellipsis div:nth-child(3) { left: 32px; animation: lds-ellipsis2 0.6s infinite; }
.lds-ellipsis div:nth-child(4) { left: 56px; animation: lds-ellipsis3 0.6s infinite; }
@keyframes lds-ellipsis1 { 0% { transform: scale(0); } 100% { transform: scale(1); }} 
@keyframes lds-ellipsis3 { 0% { transform: scale(1); } 100% { transform: scale(0); }}
@keyframes lds-ellipsis2 { 0% { transform: translate(0, 0); } 100% { transform: translate(24px, 0); } }
/* end  loader */ 
 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
